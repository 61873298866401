.mainContainer {
  counter-reset: section;
  display: flex;
  flex-direction: row;

  [data-orientation='horizontal']&,
  [data-orientation='horizontal-inline']& {
    flex-direction: row;
  }

  [data-orientation='vertical']& {
    flex-direction: column;
  }
}

.nodeContainer {
  display: grid;

  [data-orientation='horizontal'] &,
  [data-orientation='horizontal-inline'] & {
    justify-items: center;
  }

  [data-orientation='vertical'] & {
    align-items: center;
  }

  gap: theme('spacing.1');
  [data-orientation='horizontal'] & {
    grid-template-rows: 40px auto;
  }

  [data-orientation='vertical'] & {
    grid-template-columns: 40px auto;
  }

  [data-orientation='horizontal-inline'] & {
    grid-template-rows: 40px;
  }
}

.nodeIcon {
  display: flex;
  grid-column: auto;
  align-items: center;
  justify-items: center;

  [data-orientation='horizontal'] & {
    min-width: 108px;
    width: 100%;
    flex-direction: row;
  }

  [data-orientation='vertical'] & {
    min-height: 108px;
    height: 100%;
    flex-direction: column;
  }

  [data-orientation='horizontal-inline'] & {
    min-width: 185px;
    width: 100%;
    flex-direction: row;
  }
}

.checkIcon {
  display: none;
}

.nodeInnerIcon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: theme('spacing.2');
  box-sizing: border-box;
  z-index: 1;

  background: white;

  [data-status='current'] & {
    border-color: theme('colors.secondary500');
  }

  [data-status='completed'] & {
    background-color: theme('colors.secondary500');
    border-color: theme('colors.secondary500');
    & .checkIcon {
      display: block;
    }
    &::before {
      content-visibility: hidden;
    }
  }

  border: 2px solid theme('colors.tertiary50');
  border-radius: theme('borderRadius.8');

  &::before {
    @apply text-caption-lg-bold;
    counter-increment: section;
    content: counter(section);
  }
}

.nodeConnectorIcon {
  background-color: theme('colors.tertiary50');
  [data-status='completed'] & {
    background-color: theme('colors.secondary500');
  }

  [data-status='current'] &:first-child {
    background-color: theme('colors.secondary500');
  }

  &:first-child {
    [data-orientation='horizontal'] & {
      margin-right: -5px;
    }

    [data-orientation='vertical'] & {
      margin-bottom: -5px;
    }

    .nodeContainer:first-child & {
      visibility: hidden;
    }
  }

  &:last-child {
    [data-orientation='horizontal'] [data-position='newline'] & {
      margin-left: -5px;
    }

    [data-orientation='vertical'] [data-position='newline'] & {
      margin-top: -5px;
    }
    .nodeContainer:last-child & {
      visibility: hidden;
    }
  }

  [data-orientation='horizontal'] &,
  [data-orientation='horizontal-inline'] & {
    flex: auto;
    height: 2px;
  }

  [data-orientation='vertical'] & {
    flex: auto;
    width: 2px;
  }
}

.nodeTitle {
  @apply text-body-sm-bold;
  padding: 0;
  margin: 0;
  color: theme('colors.tertiary500');

  [data-orientation='horizontal'] & {
    text-align: center;
  }
}

.nodeDescription {
  @apply text-caption-lg-regular;
  padding: 0;
  margin: 0;
  color: theme('colors.tertiary300');
  text-align: left;
  [data-orientation='horizontal'] & {
    text-align: center;
  }
}

.nodeLabel {
  display: none;
  [data-orientation='horizontal-inline'] [data-position='inline']& {
    display: flex;
    flex-direction: column;
    justify-items: center;
    margin-left: theme('spacing.1');
    margin-right: theme('spacing.1');
  }

  [data-orientation='horizontal'] [data-position='newline']&,
  [data-orientation='vertical'] [data-position='newline']& {
    display: flex;
    flex-direction: column;
    justify-items: center;
  }

  [data-orientation='horizontal-inline'] & {
    max-width: 90px;
  }
}
